.trend-container {
  padding: 5rem 10rem;
  background-color: #efefef6c;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trend-container > div {
  width: 30%;
}
.trend-container > div:first-child {
  width: 35%;
}
.title {
  font-size: 1.4rem;
  font-family: "bold";
}
.trend-container > div:first-child > p {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: 1rem 0;
}
.author {
  display: flex;
  gap: 1rem;
  align-self: center;
}
.author > img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}
.authorname {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: "semibold";
}
.occupation {
  font-size: 0.8rem;
}
.trend-box {
  padding: 1rem;
  display: flex;

  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  outline: 2px solid white;
  background-color: #efefefbe;
}
.trend-box > div > p {
  font-size: 0.8rem;
}
.trend-box > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trend-box:last-child > div > img {
  background-color: rgba(255, 199, 222, 0.589) !important;
}
.trend-box > div > img {
  background-color: rgba(199, 255, 247, 0.589);
  padding: 0.5rem;
  border-radius: 50%;
  overflow: visible;
  height: 2.5rem;
}
.trend-box > h1 {
  font-size: 1rem;
  font-family: "semibold";
}
.trend-box > p {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .trend-container {
    padding: 2rem 5rem;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .trend-container {
    padding: 2rem 5rem;
    flex-direction: column;
  }
  .trend-container > * {
    width: 100% !important;
  }

  .trend-box {
    margin-top: 2rem;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .trend-container {
    padding: 1rem 2rem;
    padding-bottom: 5rem;
  }
  .trend-container > div:first-child > p {
    font-size: 1.1rem;
  }
  .author {
    margin-top: 3rem;
  }
  .authorname {
    font-size: 1.1rem;
  }
  .occupation {
    font-size: 1rem;
  }
  .trend-box > div > p {
    font-size: 1rem;
  }
  .trend-box > h1 {
    font-size: 1.2rem;
  }
  .trend-box > p {
    font-size: 1.1rem;
  }
}
