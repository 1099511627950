.testimonial-container {
  background-color: #efefef98;

  width: 100%;
  height: max-content;
  padding: 5rem 10rem;
}
.testimonial-container > p {
  font-size: 1rem;
  gap: 1rem;
  width: max-content;
  margin: auto;
  margin-bottom: 2rem;
  font-family: "light";
  display: flex;
  align-items: center;
}
.testimonial-container > p::before {
  display: block;
  content: "";
  width: 3rem;
  height: 0.1rem;
  background-color: black;
}

.testimonial-container > h1 {
  text-align: center;
  line-height: 3rem;
  margin-bottom: 3rem;
  font-family: "semibold";
  font-size: 2rem;
}
.testimonial-holder {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.testimonial-item {
  padding: 1rem;
}
.testimonial-item > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.testimonial-item > div > img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}
.testimonial-item > div > div > h1 {
  font-size: 1rem;
  font-family: "semibold";
}
.testimonial-item > div > div > h2 {
  font-size: 0.8rem;
}
.testimonial-item > p {
  font-family: "semibold";
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1100px) {
  .testimonial-container {
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  .testimonial-holder {
    flex-direction: column;
  }
  .testimonial-container > p {
    font-size: 1.3rem;
  }
  .testimonial-item > div > div > h1 {
    font-size: 1.3rem;
  }
  .testimonial-item > div > div > h2 {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .testimonial-item > p {
    font-size: 1.2rem;
  }
}
