.navbar-container {
  width: 100%;
  background-color: #efefef67;

  z-index: 10;
  padding: 0 10rem;
  height: 8rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-container > h1 {
  font-size: 1.8rem;
  font-family: "bold";
}
.navbar-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar-links > a {
  font-family: "semibold";
  color: #020202b7;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  text-decoration: none;
}
.navbar-links > a:hover {
  color: rgb(0, 132, 255);
}
.navbar-container > button:hover {
  background-color: #000;
  cursor: pointer;
  color: white;
}
.navbar-container > button {
  all: unset;
  transition: all 0.3s ease;
  padding: 0.9rem 2.5rem;
  outline: 1px solid black;
  border-radius: 55px;
  font-family: "semibold";
  font-size: 0.9rem;
}

@media screen and (max-width: 1040px) {
  .navbar-container {
    padding: 0 5rem;
  }
}

@media screen and (max-width: 840px) {
  * {
    font-size: 80%;
  }
  .navbar-container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 600px) {
  * {
    font-size: 60%;
  }
  .navbar-container > button {
    background-color: black;
    color: white;
  }
  .navbar-container > h1 {
    font-size: 2rem;
  }
  .navbar-container {
    padding: 0 2rem;
  }
  .navbar-links {
    display: none;
  }
}
