.hero-container {
  margin-top: 8rem;
  padding: 0 10rem;
  background-color: #efefef6c;

  height: 40rem;
  align-items: center;
  display: flex;
}
.hero-left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.hero-right {
  flex: 1 1;
  position: relative;
  height: 100%;
}
.sp-text {
  display: flex;
  align-items: center;
}
.sp-text::before {
  display: block;
  margin-right: 1rem;
  background-color: black;
  content: "";
  height: 5px;
  width: 2rem;
  border-radius: 20px;
}
.hero-title {
  font-family: "bold";
  font-size: 3.5rem;
}
.hero-text {
  font-size: 0.9rem;
  width: 70%;
  line-height: 2rem;
}
.hero-btn {
  display: flex;
  gap: 2rem;
}
.hero-btn > button:first-child:hover {
  background-color: #efefef00;
  transition: all 0.3s ease;
  color: #000;
}
.hero-btn > button:first-child {
  all: unset;
  padding: 1rem 2.5rem;
  outline: 1px solid black;
  background-color: black;
  color: white;
  transition: all 0.3s ease;
  border-radius: 55px;
  font-family: "semibold";
  font-size: 0.9rem;
}
.sp-btn {
  all: unset;

  display: flex;
  gap: 1rem;
  font-family: "semibold";
  align-items: center;
}
.sp-btn > img {
  height: 3rem;
}
.hero-right > * {
  position: absolute;
}
.hero-right > h1 {
  font-family: "bold";
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 10rem;
  bottom: 0;
  opacity: 0.5;
  color: white;
}

.outer-border {
  right: 0;
  margin-top: 3.5rem;
  padding: 1.5rem;
  outline: 1px solid black;
  border-radius: 300px;
}
.inner-border {
  height: max-content;
}
.outer-border > img {
  height: 30rem;
  outline: 1px solid black;

  width: 20rem;
  border-radius: 300px;
  object-fit: cover;
}

@media screen and (max-width: 960px) {
  .hero-container {
    padding: 0 5rem;
  }
  .hero-right > h1 {
    font-size: 8rem;
  }
}
@media screen and (max-width: 800px) {
  .hero-container {
    padding: 0 2rem 2rem;

    height: max-content;
    flex-direction: column-reverse;
  }
  .sp-text {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .hero-text {
    font-size: 1rem;
  }
  .sp-btn {
    font-size: 1rem;
  }
  .hero-right > * {
    position: relative;
  }
  .hero-right > h1 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .hero-container {
    padding: 0 2rem 1rem;

    height: max-content;
    flex-direction: column-reverse;
  }
  .sp-text {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .hero-text {
    font-size: 1.2rem;
    width: 100%;
  }
  .sp-btn {
    font-size: 1.2rem;
  }
  .hero-right > * {
    position: relative;
  }
  .hero-right > h1 {
    font-size: 6rem;
  }
}
