.footer-container {
  width: 100%;
  background-color: #efefef6c;

  padding: 3rem 10rem;
}
.brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
.brands > img {
  width: 10%;
}
.bottom-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  align-items: baseline;
}
.bottom-info > div:nth-child(1) > h1 {
  font-family: "bold";
}
.li:hover {
  cursor: pointer;
  color: blue;
}
.bottom-info > div:nth-child(1) > p {
  max-width: 18rem;
  line-height: 1.5rem;
  margin-top: 2rem;
}
.bottom-info > div:nth-child(2) > h2 {
  font-size: 1rem;
  font-family: "semibold";
  margin-bottom: 2rem;
}
.bottom-info > div:nth-child(3) > h2 {
  font-size: 1rem;
  font-family: "semibold";
  text-transform: uppercase;

  margin-bottom: 2rem;
}
.bottom-info > div:nth-child(2) > p {
  margin: 1rem 0;
}
.end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
}
.end > p {
  font-size: 0.9rem;
  font-family: "semibold";
}

.email-hold {
  margin-top: 2rem;
}
.email-input {
  all: unset;
  outline: 1px solid #000;
  margin-right: 1rem;
  width: 10rem;
  border-radius: 20px;
  height: 1rem;
  padding: 0.8rem;
  border-radius: 25px;
  padding-left: 2rem;
  font-family: "semibold";
}
.submit {
  outline: 1px solid #000;

  all: unset;
  background-color: black;
  color: white;
  text-align: center;
  width: 6rem;
  font-family: "semibold";

  border-radius: 25px;
  height: 1rem;
  padding: 0.9rem;
}
.submit:hover {
  color: black;
  background-color: rgba(255, 255, 255, 0);
  outline: 1px solid black;
}
* {
  /* outline: 1px solid red; */
}
@media screen and (max-width: 1100px) {
  .footer-container {
    padding: 3rem 5rem;
  }
  .brands {
    width: 100%;
  }
  .brands > img {
    width: 15%;
  }
}
@media screen and (max-width: 800px) {
  .footer-container {
    padding: 3rem 2rem;
  }
  .bottom-info > div:nth-child(1) > h1 {
    font-size: 2rem;
  }
  .bottom-info > div:nth-child(1) > p {
    font-size: 1rem;
  }
  .bottom-info > div:nth-child(2) > p {
    font-size: 1rem;
  }
  .submit,
  .email-input {
    font-size: 0.9rem;
  }
  .end > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .bottom-info {
    flex-direction: column;
  }
  .bottom-info > * {
    width: 100%;
    margin-bottom: 2rem;
  }

  .footer-container {
    padding: 3rem 2rem;
  }
  .bottom-info > div:nth-child(1) > h1 {
    font-size: 2rem;
  }
  .bottom-info > div:nth-child(1) {
    width: 100%;
  }
  .bottom-info > div:nth-child(1) > p {
    font-size: 1.2rem;
    width: 100%;
  }
  .bottom-info > div:nth-child(2) > p {
    font-size: 1.2rem;
  }

  .submit,
  .email-input {
    font-size: 1rem;
    height: 2rem;
  }
  .end > p {
    font-size: 1.1rem;
  }
}
