.virtual-container {
  display: flex;
  background-color: #efefefc0;
  align-items: center;
  padding: 5rem 10rem;
}
.virtual-left,
.virtual-right {
}
.virtual-left {
  width: 40%;
  position: relative;
}
.outer-virtual-container {
  width: max-content;
  padding: 1.5rem;
  outline: 1px solid black;
  border-radius: 300px;
}
.outer-virtual-container > img {
  height: 30rem;
  outline: 1px solid black;

  width: 20rem;
  border-radius: 300px;
  object-fit: cover;
}

.virtual-right > h1 {
  font-size: 3.5rem;
  font-family: "bold";
}
.virtual-right > h2 {
  font-size: 2rem;
  font-family: "semibold";
  line-height: 2.5rem;
}
.virtual-hero-right {
  width: 60%;
  gap: 3rem;
  display: flex;
  height: 34rem;
  align-items: center;
}
.virtual-right {
  gap: 3rem;
  display: flex;
  flex-direction: column;
}
.virtual-right > div {
  display: flex;
  gap: 2rem;
}
.virtual-item {
}
.virtual-item > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.virtual-item > div > img {
  padding: 0.5rem;
  background-color: rgb(177, 219, 255);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}
.virtual-item > div > h3 {
  font-size: 1rem;
  font-family: "semibold";
}

.virtual-item > p {
  margin-top: 1rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 1250px) {
  .virtual-container {
    padding: 2.5rem 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .virtual-container {
    flex-direction: column;
  }
  .virtual-container > * {
    width: 100%;
  }
  .virtual-left {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1000px) {
  .virtual-right > div {
    width: 100%;
    flex-direction: column;
  }
  .virtual-item > div > h3 {
    font-size: 1.2rem;
  }
  .virtual-item > p {
    font-size: 1.1rem;
  }
  .virtual-container {
    padding-bottom: 5rem;
  }

  .virtual-left {
    margin-bottom: 3rem;
  }
}
