@font-face {
  font-family: "bold";
  src: url("../src/assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "semibold";
  src: url("../src/assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "normal";
  src: url("../src/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "light";
  src: url("../src/assets/fonts/Montserrat-Light.ttf");
}
.App {
  position: relative;
}
.all {
  width: 100%;
  height: 100vh;
  background-color: #efefef67;

  position: absolute;
  border-radius: 50%;
  right: 0;
  filter: blur(240px);
  z-index: -5;
}
* {
  margin: 0;
  transition: all linear 0.5s;

  padding: 0;
  font-family: "normal";
  box-sizing: border-box;
  /* outline: 1px solid #2bff00; */
}
.blur {
  width: 70%;
  height: 15rem;
  background: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  right: 0;
  filter: blur(140px);
  z-index: -9;
}
.two {
  background: rgba(253, 43, 253, 0.69);
}
