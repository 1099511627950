.admin-container {
  padding: 5rem 10rem;
  display: flex;
  height: 40rem;
  align-items: center;
}
.admin-left,
.admin-right {
  flex: 1 1;
}
.admin-left > .p {
  font-size: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.admin-left > .p::before {
  display: block;
  content: "";
  width: 3rem;
  height: 0.1rem;
  background-color: black;
}

.admin-left > h2 {
  font-family: "semibold";
  font-size: 2rem;
  margin: 2rem 0;
  line-height: 3rem;
}
.admin-left > h1 {
  font-size: 3rem;
  margin: 2rem 0;

  font-family: "bold";
}
.main-ad-text {
  all: unset;
  font-size: 0.9rem;
  line-height: 1.6rem;
}
.admin-right {
  position: relative;
  height: 100%;
}
.admin-right > * {
  position: absolute;
}
.circle-art {
  width: 15rem;
  bottom: 5rem;
  left: 7rem;
  height: 15rem;
  border-radius: 50%;
  background-color: rgba(168, 18, 255, 0.507);
}
.startup {
  padding: 1.5rem;
  width: 10rem;
  top: 2rem;
  left: 5rem;
  height: 13rem;
  outline: 2px solid white;
  background: rgba(255, 255, 255, 0.596);

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.startup > img {
  height: 70%;
  object-fit: contain;
}
.startup > p {
  text-align: center;
  font-size: 1rem;
}
.startup > p:last-child {
  font-family: "bold";
}
.box-two {
  outline: 2px solid white;

  width: 15rem;
  height: 16rem;
  right: 0;
  display: flex;
  padding: 2rem;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.596);
}
.box-two > img {
  width: 5rem;
  border-radius: 50%;
  height: 5rem;
  object-fit: cover;
}
.box-two > h1 {
  font-family: "semibold";
  font-size: 1rem;
  margin-bottom: 0.7rem;
}
.box-two > p {
  font-size: 0.9rem;
  font-family: "semibold";
}
.box-two > h2 {
  font-family: "semibold";
  font-size: 1.8rem;
}
.card {
  width: 20rem;
  outline: 2px solid white;

  height: 12rem;
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.596);

  bottom: 0;
  right: 0;
}
.card > p {
  line-height: 1.5rem;
}
.card > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.card > div > img {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
  border-radius: 50%;
}
.card > div > div > h1 {
  margin-bottom: 0.5rem;
  font-family: "semibold";
  font-size: 1.3rem;
}
.card > div > div > p {
  font-size: 0.9rem;
}

@media screen and (max-width: 1200px) {
  .admin-container {
    padding: 2rem 5rem;
  }
  .startup {
    left: -1rem;
  }
  .box-two {
    right: 0rem;
    top: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .admin-container {
    height: max-content;
    flex-direction: column;
    padding: 2rem;
  }
  .main-ad-text {
    font-size: 1.2rem;
  }
  .admin-right {
    padding: 2rem 0;
    position: unset;
    display: flex;
    height: max-content;
    justify-content: space-between;
  }
  .startup,
  .box-two,
  .card {
    width: 30% !important;
    height: 20rem;
    position: unset;

    top: unset;
    left: unset;
    right: unset;
    left: unset;
  }
  .admin-right {
    display: flex;
    width: 100%;
    height: max-content;
  }
  .card > p {
    font-size: 1.2rem;
  }
  .circle-art {
    z-index: -3;
  }
}

@media screen and (max-width: 600px) {
  admin-container {
    padding: 5rem 2rem;
  }
  .startup,
  .box-two,
  .card {
    width: 100% !important;
  }

  .admin-right {
    flex-direction: column;
    gap: 2rem;
  }
  .admin-left > .p {
    font-size: 1.2rem;
  }
  .main-ad-text {
    line-height: 1.8rem;
    font-size: 1.32rem;
  }
  .startup > p {
    font-size: 1.4rem;
  }
  .box-two > h1 {
    font-size: 1.4rem;
  }
  .box-two > p {
    font-size: 1.3rem;
  }
  .card > p {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  .card > div > div > h1 {
    font-size: 1.5rem;
  }
  .card > div > div > p {
    font-size: 1.3rem;
  }
}
